table {
  width: 100%;
  border-collapse: collapse;
}

thead th {
  text-align: left;
  border-bottom: 2px solid black;
}

thead th, thead th button {
  cursor: pointer;
}

thead button {
  border: 0;
  border-radius: none;
  font-family: inherit;
  font-weight: 700;
  font-size: inherit;
  padding: 0.5em;
  margin-bottom: 1px;
  background: none;
}

thead button:focus { outline: none; }

thead button.ascending::after {
  content: '↓';
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  content: '↑';
  display: inline-block;
  margin-left: 1em;
}

tbody td {
  padding: 0.5em;
  border-bottom: 1px solid #ccc;
}

.initialColumn {
  background:white;
}

tbody tr:hover, tbody tr:hover .initialColumn {
  background-color: #eee;
}
